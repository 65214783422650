import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/details-layout"
import Seo from "../../components/seo"
import * as styles from "../../less/styles.scss"
import "./charter.scss"

import Shuttle from "./components/charter-tours"

const ShuttleMe = () => (
  <Layout>
    <Seo title="D-Golden Tours | D-Golden Tours Shuttle Me Services " />
    <Shuttle/>
  </Layout>
)

export default ShuttleMe;
